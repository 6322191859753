import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom";
import DefaultLayout from "pages/_layouts/default";
import AdminLayout from "pages/_layouts/admin";
import CONSTANTS from "common/constants";
import { logger } from "common/utils/logger.utils";
import { getParamsFromURL, onUserLoaded } from "common/utils";
import { ahaSsoLogout } from "app/hooks/auth/authManager";
import { useSelector } from "react-redux";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import Breadcrumbs from "components/BreadCrumbs";

interface PrivateRouteProps extends RouteProps {
  component: any;
  roles: any;
  adminRoute?: boolean;
  hideHeaderAndFooter?: boolean;
  pageTitle: string;
}
export default function RouteWrapper({
  component: Component,
  adminRoute,
  hideHeaderAndFooter,
  pageTitle,
  roles,
  ...rest
}: PrivateRouteProps) {
  const Layout =
    adminRoute && !hideHeaderAndFooter ? AdminLayout : DefaultLayout;

  const reduxStoreRoleCode = useSelector((state: any) => {
    return state?.user.user;
  });

  const localStoreSelectedRole =
    localStorage.getItem(CONSTANTS.ROLE_SWITCH_SEL_VALUE) ??
    reduxStoreRoleCode?.roleCode;

  logger("roleSwitch Initial Value :: ", localStoreSelectedRole);

  const history = useHistory();

  useEffect(() => {
    const checkAuthRoleAccessNRoute = async () => {
      try {
        const userObjAuth = await CONSTANTS.WINDOW.userManager.getUser();
        if (userObjAuth !== null && userObjAuth !== undefined) {
          /*  user - logged in and instance available */
          onUserLoaded(userObjAuth);

          /* Logged In scenario - Automate Email UI case */
          const { pathName, params, searchParamsUrl } = getParamsFromURL();
          localStorage.setItem("TransLogAutoFillPath", pathName ?? "");
          localStorage.setItem(
            "TransLogAutoFillParams",
            JSON.stringify(params)
          );
          localStorage.setItem(
            "RedirectUrlParams",
            JSON.stringify(searchParamsUrl)
          );

          if (
            typeof roles !== "undefined" &&
            roles?.indexOf(localStoreSelectedRole) === -1
          ) {
            /* user - logged in  ::: checking role based access */
            store.dispatch(
              showToast({
                type: "danger",
                title: " ",
                message: `Access Denied, You Don’t Have Permission To Access this URL`,
              })
            );

            /* if user doesnot have access roles then navigate to dashboard  */
            history.push("/dashboard");
          }
        } else if (window.location.href.includes("#id_token")) {
          /* user is logged in and Location page loading */
          CONSTANTS.WINDOW.userManager
            .signinRedirectCallback()
            .catch((err: any) => {
              logger(err);
            });
        } else {
          /* New Tab :: User Logged In Scenario */
          if (
            localStorage.getItem("userToken") &&
            localStorage.getItem("userToken") !== null
          ) {
            return null;
          }
          /* user is NOT logged in and trying to access protected routes like dashboard,clients ... */
          if (
            roles?.indexOf(localStoreSelectedRole) > -1 ||
            window?.location?.pathname === "/NotFound"
          ) {
            /* user is NOT logged in and
             Role Access is checked 
             - specific scenario - settings/ROLE_MENUS ::::
             Invalid URL on unauthenticated Page
             */
            return null;
          }
          /* User is NOT logged in ::: 
          NO user role Access ::: 
          Valid URL before Login --> Navigate to Login Page
           */

          const { pathName, params, searchParamsUrl } = getParamsFromURL();
          localStorage.setItem("TransLogAutoFillPath", pathName ?? "");
          localStorage.setItem(
            "TransLogAutoFillParams",
            JSON.stringify(params)
          );
          localStorage.setItem(
            "RedirectUrlParams",
            JSON.stringify(searchParamsUrl)
          );

          window.location.href = "/";
        }
      } catch (error) {
        logger("error inside catch block :: ", error);
        ahaSsoLogout();
      }

      /* Default Redirect */
      return <Redirect to="/dashboard" />;
    };

    /* Calling Async Function to check 
    user Authentication,Role Based Access 
    & routing is decided based on the result */
    checkAuthRoleAccessNRoute();

    return () => {};
  }, [pageTitle]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <div>
            <Breadcrumbs routePath={rest.path} />
            <Component {...props} />
          </div>
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  adminRoute: false,
  hideHeaderAndFooter: false,
};
