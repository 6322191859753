import styled from "styled-components";

export const HeadingLabelWrapper = styled.div`
  .menuheading {
    color: var(--primary-black);
    font-size: 1.875rem;
    opacity: 1;
    margin-bottom: 1.75rem;
    margin-top: 1.875rem;
    padding-bottom: 0.625rem;
    font-weight: 400;
    border-bottom: 1px solid var(--aui-header-borderclr);
  }
`;

export const BorderBottomStyles = styled.div`
  .border-bottom-styles {
    border-bottom: 2px solid var(--aui-header-borderclr);
    margin: 2rem 0rem;
  }
`;

export const consumerTypeFilterOptions = [
  {
    label: "Push",
    value: "push",
  },
  {
    label: "Pull",
    value: "pull",
  },
];

export const navigateToConsumerListingTab = (
  history: any,
  setChangeCompOnView: any
) => {
  // Get the current URL parameters
  const currentUrlParams = new URLSearchParams(window.location.search);

  // Set or update the 'view' query parameter
  currentUrlParams.delete("view");

  // Push the new URL with updated query parameters to history
  history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
  setChangeCompOnView("list-consumer");
};

export const validateOptionalFieldsState = (state: any) => {
  const errors: any = {};

  // Validate consumerConfig.maxAckPending
  const maxAckPendingRegex = /^[0-9]+$/;
  if (
    !!state.consumerConfig?.maxAckPending &&
    !maxAckPendingRegex.test(String(state.consumerConfig.maxAckPending))
  ) {
    errors.maxAckPending = "Max Ack Pending should contain only numbers.";
  }

  // Validate consumerConfig.name
  const consumerConfigNameRegex = /^[A-Za-z_]+$/;
  if (
    !!state.consumerConfig?.name &&
    (typeof state.consumerConfig.name !== "string" ||
      state.consumerConfig.name.trim() === "" ||
      !consumerConfigNameRegex.test(state.consumerConfig.name))
  ) {
    errors.consumerConfigName =
      "Consumer Name should contain only alphabets or special character(underscore)";
  }

  // Validate description
  if (
    !!state.description &&
    (typeof state.description !== "string" || state.description.trim() === "")
  ) {
    errors.description = "description should be a non-empty string.";
  }

  // Return errors object if any errors found, else return true
  if (Object.keys(errors).length > 0) {
    return { valid: false, errors };
  }
  return { valid: true };
};

export function validateMandatoryFieldsState(state: any, checkedCount: any) {
  const errors: any = {};

  // Validate clientCode
  if (!state.clientCode?.value || state.clientCode.value.trim() === "") {
    errors.clientCode = "clientCode should have a non-empty string value.";
  }

  // Validate streamId
  if (!state.streamId?.value || typeof state.streamId.value !== "number") {
    errors.streamId = "streamId should have a non-empty number value.";
  }

  // Validate type
  if (!state.type?.value || state.type.value.trim() === "") {
    errors.type = "type should have a non-empty string value.";
  }

  // subscription validation
  if (checkedCount === 0) {
    errors.subscription = "Please select at least one subscription.";
  }

  // Return errors object if any errors found, else return true
  if (Object.keys(errors).length > 0) {
    return { valid: false, errors };
  }
  return { valid: true };
}
