import styled from "styled-components";

export const JsonEditorStyled = styled.div`
  .editor-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 1.5rem;
  }
  .editor-wrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    padding-left: 1.5rem;
  }
  .editor-wrapper * {
    font: normal normal normal 16px/27px Montserrat !important;
  }
`;
