import CONSTANTS from "common/constants";
import axiosAccount from "./ApiServices";

export const TransactionalLogList = async (paramsData: any): Promise<any> => {
  return axiosAccount?.get(
    `${CONSTANTS.API_SERVICE_ENDPOINT.HUB_V1}/transactionLogs`,
    {
      params: {
        ...paramsData,
      },
    }
  );
};

export const TransactionalLogSummary = async (
  paramsData: any
): Promise<any> => {
  return axiosAccount.get(
    `${CONSTANTS.API_SERVICE_ENDPOINT.HUB_V1}/transactionLogChannelSummary`,
    {
      params: {
        days: paramsData,
      },
    }
  );
};

export const TransactionalLogDetails = async (
  paramsData: any
): Promise<any> => {
  return axiosAccount.get(
    `${CONSTANTS.API_SERVICE_ENDPOINT.HUB_V1}/transactionLogs/${paramsData}`
  );
};

export const TransactionalLogFilter = async (
  paramsData: string
): Promise<any> => {
  return axiosAccount.get(
    `${CONSTANTS.API_SERVICE_ENDPOINT.HUB_V1}/transactionLogs/filters`,
    {
      params: {
        columnData: paramsData,
      },
    }
  );
};
