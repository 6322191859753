import React from "react";
import { useQuery } from "react-query";
import { GetStreamDetailsByID } from "services/api/nats.api";
import TabsComp from "components/Tabs";
import { formatDate } from "common/utils";
import { HeadingLabelWrapper } from "pages/Configuration/utils";
import store from "app/store";
import { setStreamDetailsData } from "pages/Configuration/common/StreamList.slice";
import StreamDetailsStyles from "./styled";
import DetailsTabComp from "./DetailsTabComp";
import ConsumerListingComp from "./ConsumerTabComp";

interface IStreamDetailsProps {
  match: {
    params: {
      id: number;
    };
  };
}

interface StreamConfig {
  name: string;
  description: string;
  subjects: string[];
  retention: string;
  max_consumers: number;
  max_msgs: number;
  max_bytes: number;
  discard: string;
  max_age: number;
  max_msgs_per_subject: number;
  storage: string;
  num_replicas: number;
  duplicate_window: number;
  compression: string;
  allow_direct: boolean;
  mirror_direct: boolean;
  consumer_limits: Record<string, unknown>;
}

interface IStream {
  data: any;
  ID: number;
  name: string;
  CreatedAt: string;
  UpdatedAt: string;
  client_id: number;
  Description: string;
  Subject: string;
  stream_config: StreamConfig;
  client_name: string;
  client_code: string;
}

const StreamDetailsComp = (props: IStreamDetailsProps) => {
  const { data: streamsDetailsData, isFetching: loading } = useQuery(
    "stream-details-by-id",
    () => GetStreamDetailsByID(props?.match?.params?.id),
    {
      select: (data): IStream => {
        return data?.data?.stream;
      },
    }
  );

  /* Dispatch stream details to store so that same data can be used for consumer tabs */
  store.dispatch(setStreamDetailsData(streamsDetailsData));

  const DetailsTabData = [
    { id: "streamName", label: "Stream Name", value: streamsDetailsData?.name },
    {
      id: "clientName",
      label: "Client Name",
      value: streamsDetailsData?.client_name ?? "-",
    },
    {
      id: "clientCode",
      label: "Client Code",
      value: streamsDetailsData?.client_code ?? "-",
    },
    {
      id: "clientId",
      label: "Client Id",
      value: streamsDetailsData?.client_id ?? "-",
    },
    {
      id: "subject",
      label: "Subject",
      value: streamsDetailsData?.Subject ?? "-",
    },
    {
      id: "retention",
      label: "Retention",
      value: streamsDetailsData?.stream_config?.retention ?? "-",
    },
    {
      id: "createdAt",
      label: "Created At",
      value:
        formatDate(streamsDetailsData?.CreatedAt ?? "", "time-minute") ?? "-",
    },
    {
      id: "description",
      label: "Description",
      value: streamsDetailsData?.Description ?? "-",
    },
  ];

  /* Render only when tab activates */
  const searchParams = new URLSearchParams(window.location.search);
  const isStreamDetails = searchParams.get("tab") === "stream-details";
  const isConsumer = searchParams.get("tab") === "consumers-listing";

  const activeTab = isStreamDetails ? "details-tab" : "consumers-tab";

  const TabsConfigData = [
    {
      heading: "Stream Details",
      contentComp: isStreamDetails && (
        <DetailsTabComp
          detailsData={DetailsTabData}
          detailsJSONdata={streamsDetailsData?.stream_config}
        />
      ),
      tabid: "details-tab",
      urlTabName: "stream-details",
    },
    {
      heading: "Consumers",
      contentComp: isConsumer && (
        <ConsumerListingComp streamID={props?.match?.params?.id} />
      ),
      tabid: "consumers-tab",
      urlTabName: "consumers-listing",
    },
  ];

  return (
    <StreamDetailsStyles>
      {/* Heading */}
      <HeadingLabelWrapper>
        <h1
          className="menuheading"
          aria-label={`Stream : ${streamsDetailsData?.name}`}
          data-testid="view-stream"
        >
          Stream : {loading ? "-" : streamsDetailsData?.name}
        </h1>
      </HeadingLabelWrapper>

      {/* Tabs Component */}
      <TabsComp tabs={TabsConfigData} activeTab={activeTab} />
    </StreamDetailsStyles>
  );
};

export default StreamDetailsComp;
