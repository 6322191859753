import { useContext, useState } from "react";
import PermissionContext from "./PermissionContext";
import { Permission } from "./Types";

const usePermission = (permission: Permission) => {
  const [loading, setLoading] = useState(true);
  const [allowed, setAllowed] = useState<boolean>();

  const { isAllowedTo } = useContext(PermissionContext);

  isAllowedTo(permission).then((allowedPermission) => {
    setLoading(false);
    setAllowed(allowedPermission);
  });
  return [loading, allowed];
};

export default usePermission;
