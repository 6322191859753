import styled from "styled-components";

export const NoDataWrapper = styled.div`
  width: 100%;
  height: 100%;
  .no-data-title {
    font: normal normal 600 22px/27px Montserrat;
    letter-spacing: 0px;
    color: #222328;
    opacity: 1;
  }
  .no-data-img {
    max-width: 150px;
    max-height: 150px;
  }
  .no-data-p-tag {
    font: normal normal normal 16px/27px Montserrat;
    letter-spacing: 0px;
    color: #222328;
    opacity: 1;
  }
`;
