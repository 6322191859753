import React, { useEffect, useRef, useState } from "react";
import DynamicFilters from "components/DynamicFilters";
import ModalFilterStyled from "./styled";
import { IModalFiltersProps } from "./utils";

const ModalFilters = ({
  title,
  modalId,
  setAppliedFilters,
  appliedFilters,
  filters,
  setShowModal,
  showModal,
  modalSize,
  submitButtonTitle,
  mandatoryFieldText,
}: IModalFiltersProps) => {
  const [filtersData, setFiltersData] = useState<any>([]);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showModal]);

  const [isValidArray, setIsValidArray] = useState<any>({});
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const ref = useRef<any>(null);
  useEffect(() => {
    filters.forEach((filter) => {
      if (filter?.filterParams?.id) {
        const el = document.getElementById(filter?.filterParams?.id);
        if (el) {
          ref.current = el;
        }
        if (ref.current) {
          const { id } = ref.current;
          const isValid = ref.current.isValid(); // Do not move inside state, it will refer final useref value.
          setIsValidArray((prevState: any) => {
            return { ...prevState, [id]: isValid };
          });
        }
      }
    });
  }, [showModal, filtersData]);

  useEffect(() => {
    const calculateSum = async () => {
      const resolvedValues = await Promise.all(
        Object.values(isValidArray).map((promise: any) => {
          return promise;
        })
      );
      setIsDisabled(!resolvedValues.every((x: any) => x === true));
    };

    // Call the function to calculate the sum
    calculateSum();
  }, [isValidArray]);

  return showModal ? (
    <ModalFilterStyled>
      <div className="w-100">
        <aui-uploaddocumentmodal modalsize={modalSize ?? "auimodal-md"}>
          <div
            className={`modal auimodal ${showModal ? "show" : "fade"}`}
            id={modalId}
            aria-labelledby="aui-uploaddocumentmodalLabel"
            aria-hidden
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="d-block d-flex flex-column justify-content-start modal-content">
                <button
                  type="button"
                  className="close aui-modalclose position-absolute aui-modalclosecls"
                  onClick={() => setShowModal(false)}
                  aria-label="Close"
                >
                  <aui-icon
                    aria-hidden="true"
                    icon="closeIcon"
                    svgwidth="11"
                    svgheight="11"
                    data-dismiss="modal"
                  />
                </button>
                <div className="d-flex flex-column">
                  <div className="mt-2 pb-0 pl-0 pr-0 pt-0">
                    <h5 className="mb-0 filter-title filters-padding ">
                      {title || ""}
                    </h5>
                    <hr className="horizontal-line" />
                    {mandatoryFieldText && (
                      <p className="note m-0">{mandatoryFieldText}</p>
                    )}
                  </div>
                  <aui-uploaddocumentmodal-content>
                    <div className="filters-padding">
                      <DynamicFilters
                        filters={filters}
                        onChangeHandler={setFiltersData}
                        appliedFilters={appliedFilters}
                      />
                    </div>
                  </aui-uploaddocumentmodal-content>
                </div>
                <div className="d-flex justify-content-end mt-4 align-items-center filters-padding mob-padding-btn">
                  <aui-button
                    buttontitle="Cancel"
                    variant="link-style"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  />
                  <aui-button
                    variant="primary"
                    buttontitle={submitButtonTitle ?? "Apply"}
                    buttonclass={
                      submitButtonTitle
                        ? `${submitButtonTitle}btn `
                        : "applybtn"
                    }
                    disabled={isDisabled}
                    onClick={() => {
                      if (!isDisabled) {
                        setAppliedFilters(filtersData);
                        setShowModal(false);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </aui-uploaddocumentmodal>
      </div>
    </ModalFilterStyled>
  ) : null;
};

export default ModalFilters;
