import React from "react";
import { BorderBottomStyles } from "./utils";

export const BorderBottomComp: React.FC = () => {
  return (
    <BorderBottomStyles>
      <div className="border-bottom-styles" />
    </BorderBottomStyles>
  );
};
