export const natsConsumerListMockData = {
  status: 200,
  data: {
    natsConsumers: [
      {
        id: 167,
        name: "324132123123cghcgfbhchg",
        code: "324132123123",
        address: null,
        description: "Test Description",
        isActive: 1,
        createdAt: "2024-02-06T12:14:16Z",
        updatedAt: "2024-02-23T08:49:24Z",
      },
      {
        id: 177,
        name: "New Dev Env Setup Testing",
        code: "NewDevEnvSetupTesting",
        address: null,
        description: "Testing the new dev env setup!!",
        isActive: 1,
        createdAt: "2024-02-21T06:40:30Z",
        updatedAt: "2024-02-21T06:40:30Z",
      },
      {
        id: 176,
        name: "NAndeeshdemo",
        code: "NAndeeshdemo",
        address: null,
        description: "sadsadsa",
        isActive: 1,
        createdAt: "2024-02-20T04:50:26Z",
        updatedAt: "2024-02-20T04:50:26Z",
      },
      {
        id: 175,
        name: "dsadsadsa",
        code: "sdadsaddwq",
        address: null,
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis,",
        isActive: 1,
        createdAt: "2024-02-16T14:44:43Z",
        updatedAt: "2024-02-16T14:59:44Z",
      },
      {
        id: 174,
        name: "yadu10",
        code: "yadu10",
        address: null,
        description: "Client description",
        isActive: 1,
        createdAt: "2024-02-16T10:28:49Z",
        updatedAt: "2024-02-16T10:28:49Z",
      },
      {
        id: 173,
        name: "ewqeqw",
        code: "ewqeqw",
        address: null,
        description: "",
        isActive: 1,
        createdAt: "2024-02-12T15:21:59Z",
        updatedAt: "2024-02-12T15:21:59Z",
      },
      {
        id: 170,
        name: "wqewqeqw",
        code: "wqewqeqw",
        address: null,
        description: "SSADASD",
        isActive: 1,
        createdAt: "2024-02-08T12:05:06Z",
        updatedAt: "2024-02-08T12:05:06Z",
      },
      {
        id: 168,
        name: "1234",
        code: "1234",
        address: null,
        description: "",
        isActive: 1,
        createdAt: "2024-02-06T12:33:57Z",
        updatedAt: "2024-02-06T12:33:57Z",
      },
      {
        id: 166,
        name: "3213123213",
        code: "DataRefresh",
        address: null,
        description: "Testing",
        isActive: 1,
        createdAt: "2024-01-17T11:34:30Z",
        updatedAt: "2024-02-06T12:30:37Z",
      },
      {
        id: 165,
        name: "chatbot123",
        code: "chatbot",
        address: null,
        description: "dqwfwefewfewf",
        isActive: 1,
        createdAt: "2024-01-05T14:35:18Z",
        updatedAt: "2024-02-06T08:20:03Z",
      },
      {
        id: 1,
        name: "ADH",
        code: "ADH",
        address: null,
        description: "Adh descriptions Edit",
        isActive: 1,
        createdAt: "2022-06-10T12:27:47Z",
        updatedAt: "2023-11-27T07:29:14Z",
      },
      {
        id: 159,
        name: "Rasmus Hojlund",
        code: "RasmusHojlund",
        address: null,
        description:
          "Calculate the length of your string of text or numbers to check the number of characters it contains! Using our online character counting tool is quick and easy! This tool is great for computer programmers.to check the number of characters it contains.to",
        isActive: 1,
        createdAt: "2023-10-06T09:38:45Z",
        updatedAt: "2023-10-06T09:38:45Z",
      },
      {
        id: 158,
        name: "orioeroiew",
        code: "orioeroiew",
        address: null,
        description:
          "dasadsadeewq ncsahcduiasbc kndwqiodioqw ndsakndoasnd owjqoieiowqe xasnkdnasoi2381y83y2n dnasndonqs))@@#iodjqwiodh nodwqndoiqwoidnjk ndwqndinqiwdn idwqiodoiqwndoq",
        isActive: 1,
        createdAt: "2023-10-01T07:52:17Z",
        updatedAt: "2023-10-06T05:44:36Z",
      },
      {
        id: 156,
        name: "youtube-block",
        code: "youtubeblock",
        address: null,
        description: "This is test client",
        isActive: 0,
        createdAt: "2023-09-21T05:55:52Z",
        updatedAt: "2023-09-21T05:55:52Z",
      },
      {
        id: 155,
        name: "AuroraDB",
        code: "AuroraDB",
        address: null,
        description: "Testing",
        isActive: 1,
        createdAt: "2023-09-20T10:07:54Z",
        updatedAt: "2023-09-20T10:07:54Z",
      },
      {
        id: 154,
        name: "ddwqeedwq",
        code: "ddwqeedwq",
        address: null,
        description: "cdasdwqewqewqe",
        isActive: 1,
        createdAt: "2023-09-05T08:47:36Z",
        updatedAt: "2023-09-05T08:47:36Z",
      },
      {
        id: 153,
        name: "Pedro Duarte",
        code: "PedroDuarte",
        address: null,
        description: "dsadasdasd",
        isActive: 1,
        createdAt: "2023-09-05T06:07:08Z",
        updatedAt: "2023-09-05T06:07:08Z",
      },
      {
        id: 152,
        name: "dqwewqe",
        code: "dqwewqe",
        address: null,
        description: "",
        isActive: 1,
        createdAt: "2023-08-17T06:08:28Z",
        updatedAt: "2023-08-17T06:08:28Z",
      },
      {
        id: 151,
        name: "Intelligo",
        code: "Intelligo",
        address: null,
        description: "",
        isActive: 1,
        createdAt: "2023-08-11T10:16:54Z",
        updatedAt: "2023-08-11T10:16:54Z",
      },
      {
        id: 150,
        name: "testnotification",
        code: "testnotification",
        address: null,
        description: "Test",
        isActive: 1,
        createdAt: "2023-08-04T12:38:44Z",
        updatedAt: "2023-08-04T12:38:44Z",
      },
    ],
  },
  _pagination: {
    pageNumber: 1,
    pageOffset: 1,
    pageSize: 20,
    totalCount: 70,
    totalPages: 4,
    isFirst: 1,
    isLast: 0,
  },
  requestId: "a38b95787f18901e6277c7a7957fb921",
};
