/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import FocusTrap from "focus-trap-react";
import CONSTANTS from "common/constants";
import { useForm } from "react-hook-form";
import { getErrorMessage, toggleModalStyles } from "common/utils";
import AutoSuggestComp from "components/AutoSuggest";
import Restricted from "services/PermissionManager/Restricted";
import PermissionContext from "services/PermissionManager/PermissionContext";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { fetchPermission } from "services/PermissionManager/Types";
import { useHistory } from "react-router-dom";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import { getRoles } from "services/api/settings.api";
import { inviteUserService } from "services/api/inviteUser.api";
import { ClientListService } from "services/api/clients.api";
import { dropDownDefaultState, dropDownToggler, isClientAdmin } from "../util";
import {
  IUserInviteState,
  InviteUserModalProps,
  checkCommonConditions,
  clientArr,
} from "./util";

const imageUrl = "../images/Close.svg";

const InviteUserModal: React.FC<InviteUserModalProps> = ({
  toggleModal,
  setToggleModal,
  imageLoading,
  setImageLoading,
  stateObject,
  setRefreshWithPageNumber,
  refreshWithPageNumber,
}) => {
  // State
  const [selectClientValue, setSelectClientValue] = useState("select client");
  const [isError, setIsError] = useState(false);
  const [autoSuggestValue, setAutoSuggestValue] = useState<any>("");
  const [showClientDropDown, setShowClientDropDown] = useState<boolean>(false);
  const [defaultClientId, setDefaultClientId] = useState<any>();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [roleSelected, setRoleSelected] = useState("");
  const [selectedUserLookup, setSelectedUserLookup] = useState<any>("");
  const [inviteUserRoleData, setInviteUserRoleData] = useState<any>([]);
  const [activeClientsData, setActiveClientsData] = useState<any>([]);

  // Hooks and References
  const history = useHistory();
  const RefFocus = useRef<any>();
  const { loggedInUserObjectContext } = useContext(PermissionContext);

  // Form Handling
  let UIschema: any = "";

  const [userInviteFormData, setUserInviteFormData] =
    useState<IUserInviteState>({
      firstName: "",
      lastName: "",
      email: "",
      clientId: "0",
      role: "",
      roleCode: "",
    });

  if (loggedInUserObjectContext !== null) {
    UIschema = yup.object().shape({
      firstName: yup
        .string()
        .required("User First Name is Required")
        .matches(
          CONSTANTS.REGEX.ATLEAST_ONE_ALPHANUMERIC,
          "User First Name should contain atleast one alpha numeric character"
        )
        .max(255, "Maximum 255 characters allowed"),
      lastName: yup
        .string()
        .required("User Last Name is Required")
        .matches(
          CONSTANTS.REGEX.ATLEAST_ONE_ALPHANUMERIC,
          "User Last Name should contain atleast one alpha numeric character"
        )
        .max(255, "Maximum 255 characters allowed"),
      email: yup
        .string()
        .matches(CONSTANTS.REGEX.EMAIL, "Please enter a valid email address"),
      role: yup.string().required("Role is Required"),
      clientId:
        loggedInUserObjectContext?.roleCode ===
          CONSTANTS.USER_ROLES.SUPER_ADMIN &&
        ["CLIENT_USER", "CLIENT_ADMIN"].includes(roleSelected)
          ? yup.number().required("Client is Required")
          : yup.number().notRequired(),
    });
  }
  const { register, handleSubmit, reset, errors, getValues } = useForm({
    resolver: yupResolver(UIschema),
  });

  const getRoleCode = (roleNameStr: string) => {
    if (["SUPER_ADMIN", "SUPER_USER"].includes(roleNameStr)) {
      setShowClientDropDown(false);
    }
    return roleNameStr;
  };

  // handler for onchange invite form
  const onChangeHandler = useCallback(
    (event: any) => {
      const { name, value } = event.target;

      if (name === "clientId") {
        setDefaultClientId(value);
      }

      const updatedUserInviteFormData = {
        ...userInviteFormData,
        ...getValues(),
      };

      if (name === "role") {
        setRoleSelected(value);
        if (!showClientDropDown) {
          setDefaultClientId("");
        }
        setUserInviteFormData({
          ...updatedUserInviteFormData,
          roleCode: getRoleCode(value),
        });
      } else {
        setUserInviteFormData(updatedUserInviteFormData);
      }
    },
    [showClientDropDown]
  );
  const getClientsDataBasedOnRole = () => {
    let clientsDropdownData: any = [];
    if (fetchPermission()("account.clients.get", "")) {
      // Super Admin
      clientsDropdownData = [...activeClientsData];
    } else if (stateObject.role === "Client Admin") {
      // Client Admin
      clientsDropdownData = [
        {
          id: stateObject.clientId,
          name: stateObject.clientName,
        },
      ];
    }

    return clientsDropdownData.map((item: any) => {
      return (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      );
    });
  };

  const imageLoaded = () => {
    setImageLoading(false);
  };

  const getUserRoles = () => {
    getRoles({
      isActive: true,
      level: "gte".concat(",", `${loggedInUserObjectContext?.roleLevel}`),
    })
      .then((response: any) => {
        const { roles } = response.data;
        setInviteUserRoleData(roles === null ? [] : roles);
        return { ...response._pagination };
      })
      .catch((error: any) => {
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error Occurred",
            message: getErrorMessage(error),
          })
        );
      });
  };

  const resetModal = () => {
    reset({
      errors: 0,
      roleCode: "",
      clientId: "0",
      email: "",
      firstName: "",
      lastName: "",
    });
    setRoleSelected("");
    setShowClientDropDown(false);
    setSelectClientValue("select client");
    setUserInviteFormData({
      firstName: "",
      lastName: "",
      email: "",
      clientId: "0",
      role: "",
      roleCode: "",
    });
    setAutoSuggestValue("");
    setIsError(false);
  };
  const submitForm = async () => {
    const { roleCode, firstName, lastName, clientId } = userInviteFormData;
    const clntId = isClientAdmin(stateObject.role)
      ? stateObject.clientId.toString()
      : clientId;
    inviteUserService(firstName, lastName, autoSuggestValue, roleCode, clntId)
      .then((response: any) => {
        setIsError(false);
        store.dispatch(
          showToast({
            type: "success",
            title: "Success",
            message: "User has been invited successfully!",
          })
        );
        setUserInviteFormData({
          firstName,
          lastName,
          email: "",
          clientId: "0",
          role: "",
          roleCode: "",
        });
        setAutoSuggestValue("");
        toggleModalStyles();
        setToggleModal(false);
        setRefreshWithPageNumber(!refreshWithPageNumber);
        setImageLoading(true);
        setShowClientDropDown(false);
        resetModal();
      })
      .catch((error) => {
        if (
          error.response.data.error.message ===
          "Email-id already exists under the tenant"
        ) {
          setIsError(true);
        } else {
          store.dispatch(
            showToast({
              title: "Error",
              message: getErrorMessage(error),
            })
          );
        }
      });
  };
  function getActiveClientsRecords() {
    ClientListService({
      pageSize: CONSTANTS.MAX_RECORDS,
      pageNumber: 1,
      isActive: true,
    })
      .then((response: any) => {
        const { clients } = response.data;
        setActiveClientsData(clients || []);
        return { ...response._pagination };
      })
      .catch((error) => {
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error Occurred",
            message: getErrorMessage(error),
          })
        );
      });
  }

  // Effect to focus on modal open
  useEffect(() => {
    if (toggleModal && RefFocus.current && !imageLoading) {
      RefFocus.current.focus();
    }
  }, [toggleModal, imageLoading]);

  useEffect(() => {
    const commonCheck = checkCommonConditions(
      userInviteFormData,
      autoSuggestValue,
      isError,
      loggedInUserObjectContext,
      showClientDropDown,
      defaultClientId
    );
    if (commonCheck) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [userInviteFormData, showClientDropDown]);

  useEffect(() => {
    getUserRoles();
  }, []);

  useEffect(() => {
    const isClientRole = clientArr.includes(roleSelected);
    const hasPermission = fetchPermission()("account.clients.get", "");

    if (isClientRole && hasPermission) {
      getActiveClientsRecords();
      setShowClientDropDown(true);
    }
  }, [roleSelected]);

  return (
    <>
      {toggleModal ? (
        <FocusTrap
          focusTrapOptions={{
            initialFocus: false,
            fallbackFocus: `.modal`,
            escapeDeactivates: false,
            clickOutsideDeactivates: false,
          }}
        >
          <div
            className="modal fade show aui-org-modal aui-new-org aui-modal"
            id="InviteUser"
            tabIndex={-1}
            aria-label="Invite User Modal Window"
            aria-modal="true"
            role="dialog"
            aria-hidden={!toggleModal}
          >
            <div className="modal-dialog modal-dialog-md modal-md modal-dialog-centered inviteuser-modal modal-popup-inviteUser margin-sm modalwidth">
              <div className="modal-content">
                <div
                  className={`aui-block-loader ${
                    imageLoading ? "d-flex" : "d-none"
                  }`}
                  role="alert"
                  aria-live="assertive"
                  aria-label="Modal Content Loading"
                />
                <div
                  className={`modal-content-wrapper ${
                    imageLoading ? "d-none" : "d-block"
                  }`}
                >
                  <button
                    ref={RefFocus}
                    type="button"
                    className="close d-none d-sm-block"
                    onClick={() => {
                      toggleModalStyles();
                      setToggleModal(false);
                      setImageLoading(true);
                      setSelectClientValue("select client");
                      resetModal();
                    }}
                    aria-label="Close"
                  >
                    <img
                      src={imageUrl}
                      data-testid="crossbtn"
                      onLoad={imageLoaded}
                      alt="icon"
                      className="position-relative closestyle"
                    />
                  </button>

                  <div className="modal-header">
                    <h2
                      className="modal-title font-600 d-flex"
                      aria-label="Invite User"
                      data-testid="test-addtenant"
                      id="modal-heading"
                    >
                      <span
                        className="d-block d-sm-none rotateInverse mr-2"
                        onClick={() => {
                          toggleModalStyles();
                          setToggleModal(false);
                          setShowClientDropDown(false);
                          setSelectClientValue("select client");
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <i className="aha-icon-right-arrow-thick" />
                      </span>
                      <span
                        className={
                          stateObject.role === "Super Admin"
                            ? ""
                            : "invite-text"
                        }
                      >
                        {/* Display "Invite User" text */}
                        Invite User
                        {/* Conditionally render client name if not "Super Admin" */}
                        {stateObject.role !== "Super Admin" && (
                          <span className="tenantText font-600 modal-title">
                            {/* Display client name if available */}
                            {stateObject.clientName
                              ? ` - ${stateObject.clientName}`
                              : ""}
                          </span>
                        )}
                      </span>
                    </h2>
                  </div>
                  <p className="instruction">
                    {CONSTANTS.MODAL_INSTRUCTION_TEXT}
                  </p>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div className="modal-body p-0 pt-3">
                      <div className="form-group row required mb-4">
                        <label
                          htmlFor="emailid"
                          className="col-sm-4 col-form-label"
                          aria-label="Email"
                        >
                          Email ID
                        </label>
                        <div className="col-sm-8">
                          <AutoSuggestComp
                            onChangeHandler={onChangeHandler}
                            setIsError={setIsError}
                            setSelectedUserLookup={setSelectedUserLookup}
                            autoSuggestValue={autoSuggestValue}
                            setAutoSuggestValue={setAutoSuggestValue}
                            register={register}
                            id="emailid" // Add id for association
                          />
                          {errors.email?.message && (
                            <p role="alert" className="form-error-msg mb-0">
                              {errors.email?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {isError && (
                        <div className="form-group row required mb-3 marginAlign-errorMsg">
                          <div className="col-sm-4" />
                          <div className="col-sm-8">
                            <div className="error-block d-flex align-items-center ">
                              <i className="aha-icon-warning warning-icon p-2" />
                              <div>
                                <h4
                                  role="alert"
                                  className="error-message m-0 pt-2 pb-2 pr-3"
                                >
                                  Email ID already exists, Please choose a
                                  different one
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {!isError && (
                        <>
                          <div className="form-group row required mb-4">
                            <label
                              htmlFor="firstName"
                              className="col-sm-4 col-form-label"
                              aria-label="First name"
                            >
                              First Name
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control "
                                id="firstName"
                                required
                                name="firstName"
                                aria-required="true"
                                ref={register}
                                onChange={onChangeHandler}
                              />
                              {errors.firstName?.message && (
                                <p role="alert" className="form-error-msg mb-0">
                                  {errors.firstName?.message}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="form-group row required mb-4">
                            <label
                              htmlFor="lastName"
                              className="col-sm-4 col-form-label"
                              aria-label="Last name"
                            >
                              Last Name
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                required
                                className="form-control "
                                id="lastName"
                                name="lastName"
                                aria-required="true"
                                ref={register}
                                onChange={onChangeHandler}
                              />
                              {errors.lastName?.message && (
                                <p role="alert" className="form-error-msg mb-0">
                                  {errors.lastName?.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="form-group row required mb-4">
                            <label
                              htmlFor="selectRole"
                              className="col-sm-4 col-form-label"
                              aria-label="Select role"
                            >
                              Select Role
                            </label>
                            <div className="col-sm-8">
                              <select
                                className="form-control dropdown"
                                defaultValue=""
                                ref={register}
                                onChange={onChangeHandler}
                                name="role"
                                id="selectRole"
                                required
                                aria-required="true"
                                onClick={(e) => {
                                  dropDownToggler(e);
                                }}
                                onBlur={(e) => {
                                  dropDownDefaultState(e);
                                }}
                              >
                                <option value="" disabled hidden selected>
                                  Select
                                </option>
                                {inviteUserRoleData.map((item: any) => (
                                  <option key={item.id} value={item.code}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                              <i className="aha-icon-arrow-down forModal" />
                            </div>
                          </div>
                          {showClientDropDown && (
                            <Restricted to="account.clients.get">
                              <div className="form-group row required mb-3">
                                <label
                                  htmlFor="selectClient"
                                  className="col-sm-4 col-form-label"
                                  aria-label="select-client"
                                >
                                  Select Client
                                </label>
                                <div className="col-sm-8 tooltip-role">
                                  <div
                                    className="select-client"
                                    id="Client-tooltip"
                                  >
                                    <select
                                      className="form-control dropdown"
                                      ref={register}
                                      defaultValue={defaultClientId}
                                      name="clientId"
                                      id="selectClient"
                                      data-testid="selectClient"
                                      aria-required="true"
                                      onClick={(e) => {
                                        dropDownToggler(e);
                                      }}
                                      onBlur={(e) => {
                                        dropDownDefaultState(e);
                                      }}
                                      onChange={(e) => {
                                        onChangeHandler(e);
                                        setSelectClientValue(
                                          e.target.options[
                                            e.target.selectedIndex
                                          ].text
                                        );
                                      }}
                                    >
                                      <option
                                        value="0"
                                        disabled
                                        hidden
                                        selected
                                      >
                                        Select
                                      </option>
                                      {getClientsDataBasedOnRole()}
                                    </select>
                                    <i className="aha-icon-arrow-down forModal" />
                                    <span className="tooltiptext">
                                      {selectClientValue === ""
                                        ? "select client"
                                        : selectClientValue}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Restricted>
                          )}
                        </>
                      )}
                      <div className="modal-footer pt-1 d-flex btnGrp-addTanant justify-content-end">
                        <button
                          type="button"
                          aria-label="cancel"
                          className="btn btn-round btn-secondary mr-4 btnwidth cancel-btn"
                          onClick={() => {
                            resetModal();
                            toggleModalStyles();
                            setToggleModal(false);
                            setImageLoading(true);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          disabled={isError ? false : buttonDisabled}
                          type={isError ? "button" : "submit"}
                          aria-label={isError ? "Edit User" : "Invite User"}
                          className="btn btn-round btn-primary btnwidth invite-btn"
                          data-dismiss={isError ? "modal" : null}
                          onClick={
                            isError
                              ? () => {
                                  history.push(
                                    `${CONSTANTS.PAGE_ROUTES.USERS}/${selectedUserLookup}`
                                  );
                                }
                              : () => {}
                          }
                        >
                          {isError ? "Edit User" : "Invite"}
                        </button>
                      </div>
                      <div className="modal-footer d-block d-sm-none mt-0 pt-0">
                        <div className="btn-block btnbottom btnGrp-add">
                          <button
                            disabled={isError ? false : buttonDisabled}
                            data-testid="submitbtn"
                            type={isError ? "button" : "submit"}
                            aria-label={isError ? "Edit User" : "save"}
                            className="btn btn-round btn-primary btnwidth invite-sm-btn"
                            data-dismiss={isError ? "modal" : null}
                            onClick={
                              isError
                                ? () => {
                                    history.push(
                                      `${CONSTANTS.PAGE_ROUTES.USERS}/${selectedUserLookup}`
                                    );
                                  }
                                : () => {}
                            }
                          >
                            {isError ? "Edit User" : "Invite"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </FocusTrap>
      ) : null}
    </>
  );
};

export default InviteUserModal;
