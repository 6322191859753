import React from "react";
import { Route, Switch } from "react-router-dom";
import RouteLinks from "common/routePaths";
import PrivateRoute from "./Route";

export default function Routes() {
  return (
    <Switch>
      {RouteLinks.routePaths.map((routeItem) => {
        if (!routeItem.adminRoute) {
          return (
            <Route
              key={`${routeItem.path}-${routeItem.pageTitle}`}
              path={routeItem.path}
              exact
              component={routeItem.component as any}
            />
          );
        }
        return (
          <PrivateRoute
            key={`${routeItem.path}-${routeItem.pageTitle}`}
            path={routeItem.path}
            exact
            component={routeItem.component as any}
            adminRoute={routeItem.adminRoute}
            hideHeaderAndFooter={routeItem.hideHeaderAndFooter}
            pageTitle={routeItem.pageTitle}
            roles={routeItem.roles}
          />
        );
      })}
    </Switch>
  );
}
