/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { ListSourceEntities } from "services/api/nats.api";
import { SubscriptionStyles } from "./styled";

const DropdownItem = ({
  label,
  children,
  isChecked,
  onToggle,
  value,
  hasChildren,
  checkedItems,
  isOpen,
  onOpen,
  parentOpen,
  isChildItem,
  parentKey,
}: any) => {
  const toggleOpen = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isChildItem) {
      onOpen(value); // Only toggle open for parent items
    }
  };

  const handleCheckboxChange = (e: any) => {
    e.stopPropagation();
    const newCheckedStatus = !isChecked;
    onToggle(value, newCheckedStatus, parentKey);

    if (hasChildren) {
      children.forEach((child: { props: { value: any } }) =>
        onToggle(child.props.value, newCheckedStatus, value)
      );
    }
  };

  return (
    <li
      className={`dropdown-submenu${isOpen && parentOpen ? " open" : ""}`}
      onClick={toggleOpen} // Call toggleOpen for both parent and child
      onKeyDown={(e) =>
        e.key === "Enter" && (isChildItem ? undefined : toggleOpen(e))
      }
      role="menuitem"
      tabIndex={0}
      aria-haspopup={!!children}
      aria-expanded={isOpen}
    >
      <div className="dropdown-item-wrapper">
        <input
          type="checkbox"
          checked={isChecked}
          readOnly
          aria-checked={isChecked}
          onClick={(e: any) => {
            e.stopPropagation();
            handleCheckboxChange(e);
          }}
          id={`dropdown-menu-${value}`}
        />{" "}
        <label
          className="dropdown-input-label"
          htmlFor={`dropdown-menu-${value}`}
        >
          {label}
        </label>
        {!isChildItem && hasChildren && (
          <span className="aui-arrow-right-wrapper">
            <aui-icon
              icon="arrowright"
              svgwidth="15"
              svgheight="15"
              block={false}
            />
          </span>
        )}
      </div>
      {isOpen && parentOpen && (
        <ul className="dropdown-menu entity-dropdown-wrapper">{children}</ul>
      )}
    </li>
  );
};

const SubscriptionComp = ({
  checkedItems,
  setCheckedItems,
  checkedCount,
  setCheckedCount,
}: any) => {
  const [sourceEntitydropdownOpen, setSourceEntitydropdownOpen] =
    useState(false);

  /* API call to get source entity mapping */
  const { data: sourceEntityData } = useQuery(
    "source-entity-list",
    () => ListSourceEntities({}),
    {
      select: (data: any) => {
        return data?.data?.sourceEntitiesMap;
      },
      onSuccess: (data: any) => {},
    }
  );

  const [openDropdown, setOpenDropdown] = useState<string | null>(
    sourceEntityData && sourceEntityData?.[0]?.source
  );

  const [openChildDropdown, setOpenChildDropdown] = useState<string | null>(
    null
  );

  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectedSubscriptionRef = useRef<any>(null);

  const handleToggle = (
    key: string,
    checked: boolean,
    parentKey: string | null = null
  ) => {
    if (parentKey) {
      // Handle child checkbox change
      const itemKey = `${parentKey}:${key}`;
      setCheckedItems((prev: any) => {
        const updatedItems = { ...prev, [itemKey]: checked };

        // Find the relevant source from the sourceEntitiesMap
        const sourceItem = sourceEntityData.find(
          (item: any) => item.source === parentKey
        );

        // Check if all child checkboxes are checked
        const allChecked = sourceItem.entities.every(
          (entity: string) => updatedItems[`${parentKey}:${entity}`]
        );

        // Update parent checkbox based on child checkboxes' state
        updatedItems[parentKey] = allChecked;

        return updatedItems;
      });
    } else {
      // Handle parent checkbox change
      setCheckedItems((prev: any) => {
        const updatedItems = { ...prev, [key]: checked };

        // Find the relevant source from the sourceEntitiesMap
        const sourceItem = sourceEntityData.find(
          (item: any) => item.source === key
        );

        // Update all child checkboxes if parent is checked/unchecked
        sourceItem?.entities?.forEach((entity: string) => {
          updatedItems[`${key}:${entity}`] = checked;
        });

        return updatedItems;
      });

      setOpenDropdown(key); // to open entity dropdown on click of source checkbox
    }
  };

  const handleClearAllSubscriptions = () => {
    setCheckedItems((prev: any) => {
      const updatedItems = { ...prev };
      Object.keys(updatedItems).forEach((key) => {
        updatedItems[key] = false;
      });
      return updatedItems;
    });
  };

  const handleClear = (key: string) => {
    setCheckedItems((prev: any) => {
      const updatedItems = { ...prev };

      // Clear the parent checkbox
      updatedItems[key] = false;

      // Find the relevant source from the passed key

      const sourceItem: any = key?.split(":")[0]; // eg sourceItem = "MSD,ADH etc"

      if (sourceItem in updatedItems) {
        const sourceItemVal = updatedItems[sourceItem]; // value indicates whether the source is checked

        // Uncheck the sourceItem if it is currently true (all entities under this source are checked)
        if (sourceItemVal) {
          updatedItems[sourceItem] = false;
        }
      }
      return updatedItems;
    });
  };

  const handleOpenDropdown = (key: string) => {
    setOpenDropdown(key);
  };

  const handleOpenChildDropdown = (key: string) => {
    setOpenChildDropdown((prev) => (prev === key ? null : key));
  };

  const handleClickOutside = (event: any) => {
    const isOutsideDropdown =
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node);

    if (isOutsideDropdown) {
      setSourceEntitydropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const selectedSubscriptionsCount = (obj: any) => {
    return Object.keys(obj)?.reduce((count, key) => {
      if (obj[key] === true && key.includes(":")) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  useEffect(() => {
    const count = selectedSubscriptionsCount(checkedItems);
    setCheckedCount(count);
  }, [checkedItems]);

  const renderSelectedLabels = () => {
    return Object.keys(checkedItems)
      .filter((key: any) => checkedItems[key])
      .map((key) => {
        const [sourceKey, entity] = key.includes(":")
          ? key.split(":")
          : [key, null];
        if (!entity) return null;
        return (
          <div key={key} className="selected-label">
            {`${sourceKey} - ${entity}`}
            {/* Prepend sourceKey */}
            <aui-button
              variant="link-style"
              onClick={(e: any) => {
                e.preventDefault();
                handleClear(key);
              }}
            >
              <aui-icon icon="cross" svgwidth="9" svgheight="9" block={false} />
            </aui-button>
          </div>
        );
      });
  };

  const renderDropdownItems = () => {
    return sourceEntityData?.map((item: any) => {
      const sourceKey: any = item.source;
      const { entities }: any = item;

      return (
        <DropdownItem
          key={sourceKey}
          label={sourceKey}
          isChecked={checkedItems[sourceKey]}
          onToggle={handleToggle}
          value={sourceKey}
          hasChildren
          checkedItems={checkedItems}
          isOpen={openDropdown === sourceKey}
          onOpen={handleOpenDropdown}
          parentOpen={openDropdown === sourceKey}
        >
          {entities.map((entity: any) => (
            <DropdownItem
              key={entity}
              label={entity}
              isChecked={checkedItems[`${sourceKey}:${entity}`]}
              onToggle={handleToggle}
              value={entity}
              hasChildren={false}
              checkedItems={checkedItems}
              isOpen={openChildDropdown === entity}
              onOpen={handleOpenChildDropdown}
              parentOpen={openDropdown === sourceKey}
              isChildItem
              parentKey={sourceKey} // Pass parentKey to child items
            />
          ))}
        </DropdownItem>
      );
    });
  };

  const renderChip = (checkedCountParam: number) => {
    return (
      <div className="chip-wrapper d-flex align-items-center">
        <span className="chip d-flex align-items-center justify-content-center">{`${checkedCountParam} Selected`}</span>
        <aui-button
          variant="link-style"
          onClick={(e: any) => {
            e.preventDefault();
            handleClearAllSubscriptions();
          }}
        >
          <aui-icon icon="cross" svgwidth="9" svgheight="9" block={false} />
        </aui-button>
      </div>
    );
  };

  return (
    <SubscriptionStyles>
      <div className="row">
        <div className="col-lg-10">
          <label className="subs-heading">Subscriptions</label>
          {checkedCount > 0 && (
            <div ref={selectedSubscriptionRef} className="selected-labels">
              {renderSelectedLabels()}
            </div>
          )}
          <div className="dropdown" ref={dropdownRef}>
            <label
              htmlFor="dropdownInput"
              className="btn btn-default dropdown-toggle subs-label-placeholder"
            >
              {checkedCount < 1
                ? "Select Subscriptions"
                : renderChip(checkedCount)}
              <span className="icon-wrapper">
                <aui-icon
                  icon={sourceEntitydropdownOpen ? "arrowup" : "arrowdown"}
                  block={false}
                  svgwidth="20"
                  svgheight="20"
                />
              </span>
            </label>
            <input
              type="checkbox"
              id="dropdownInput"
              className="dropdown-input"
              data-check={sourceEntitydropdownOpen}
              onChange={() =>
                setSourceEntitydropdownOpen(!sourceEntitydropdownOpen)
              }
              checked={sourceEntitydropdownOpen}
            />
            <ul className="dropdown-menu source-dropdown-wrapper">
              {renderDropdownItems()}
            </ul>{" "}
          </div>
        </div>
      </div>
    </SubscriptionStyles>
  );
};

export default SubscriptionComp;
