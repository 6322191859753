/* eslint-disable no-restricted-syntax */
export const radioButtons = [
  {
    label: "API",
    id: "API",
    value: "API",
  },
  {
    label: "NATS",
    id: "NATS",
    value: "NATS",
  },
];

export function formatClientsData(data: any) {
  return data.map((item: any) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
}

export const defaultCredential = {
  name: "",
  permissions: {},
  clientId: 0,
  channel: "",
};

function arraysMatch(arr1: any, arr2: any) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}
export function objectsMatch(permission: any, checkboxState: any) {
  const keys1 = Object.keys(permission);
  const keys2 = Object.keys(checkboxState);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (
      !keys2.includes(key) ||
      !arraysMatch(permission[key], checkboxState[key])
    ) {
      return false;
    }
  }

  return true;
}

export function mergeObjects(
  permission: any,
  checkboxState: any,
  tobeDeletedObject: any
) {
  for (const service in permission) {
    if (Object.prototype.hasOwnProperty.call(permission, service)) {
      if (service in checkboxState) {
        for (const endpoint in permission[service]) {
          if (
            Object.prototype.hasOwnProperty.call(permission[service], endpoint)
          ) {
            const uniqueEndpoints = new Set([
              ...(checkboxState[service][endpoint] || []),
              ...permission[service][endpoint],
            ]);
            checkboxState[service][endpoint] = Array.from(uniqueEndpoints);
          }
        }
      } else {
        checkboxState[service] = { ...permission[service] };
      }
    }
  }
  if (Object.keys(tobeDeletedObject).length > 0) {
    // eslint-disable-next-line guard-for-in

    for (const service in tobeDeletedObject) {
      if (Object.prototype.hasOwnProperty.call(tobeDeletedObject, service)) {
        if (service in checkboxState) {
          for (const endpoint in tobeDeletedObject[service]) {
            if (Array.isArray(tobeDeletedObject[service][endpoint])) {
              tobeDeletedObject[service][endpoint].forEach((item: any) => {
                checkboxState[service][endpoint] = checkboxState[service][
                  endpoint
                ].filter((i: any) => i !== item);
              });

              if (checkboxState[service][endpoint].length === 0) {
                delete checkboxState[service][endpoint];
              }
            }
          }
        }
      }
    }
  }

  for (const key in tobeDeletedObject) {
    if (Object.values(tobeDeletedObject[key]).length === 0) {
      delete checkboxState[key];
    }
  }
  return checkboxState;
}

export function removeEmpty(obj: any) {
  Object.keys(obj).forEach((key) => {
    if (Object.values(obj[key]).length === 0) delete obj[key];
  });
  return obj;
}
